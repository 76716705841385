import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromRoot from '../../../../../../store/root.state';
import { ShippingActions, ShippingActionTypes } from '../actions/shipping.actions';
import { Cargo } from '../../models/shipment-cargo';
import { ShipmentInsurance } from '../../models/shipment-insurance.model';
import { DeliverySecurity } from '../../models/delivery.model';
import { Package } from '../../models/shipment-package.model';
import {
  ShipmentLocation, OriginalShipmentDetails, ShipmentActionType,
  Claim, ApprovalContact, ShippingPreset, QuickQuote, ClaimDocument, Approval
} from '../../models/base-shipment-models';
import { NameValuePair } from '../../models/name-value-pair.model';
import { PackageType } from '../../models/shipment-package-type.model';
import { ServiceType } from '../../enums/service-type';
import { formatPhoneNumber, PhoneNumber } from '../../../../../../utils/formatters';
import { Rules } from '../../models/rules.model';
import { SavedShipmentState } from '../../enums/saved-shipment-state';
import { InternationalShipmentData } from '../../models/international-shipments-data.model';
import { InternationalDetail } from '../../models/international-shipment.model';
import { ShipmentPriceType } from '../../enums/shipment-price-type.enum';
import { Quote, ShipmentStatus } from '../../models/shipment';
import { IntegratedAccountDetails } from '../../models/integrated-account-details-model';
import { HoldForPickupAddress } from '../../models/hold-for-pickup-address.model';
import { EcommStore } from '../../models/ecommerce-models';

//#region Reducer interfaces

export interface ZingState extends fromRoot.ZingState {
  shippingState: ShippingState;
}

export interface ShippingState {
  shippingPreset?: ShippingPreset;
  savingInProgress: boolean;
  cargoTypesLoaded: boolean;
  packageTypesLoaded: boolean;
  internationalShipmentsDataLoaded: boolean;
  shippingPresetsLoaded: boolean;
  shippingPresetPopulated?: boolean;
  carriersLoaded: boolean;
  businessRulesLoaded: boolean;
  cargo: Cargo[];
  packageTypes: PackageType[];
  shippingPresets: ShippingPreset[];
  carriers: any[];
  shipmentDetails: ShipmentDetails;
  originalShipmentDetails: OriginalShipmentDetails;
  shipmentActionType: ShipmentActionType;
  shipmentCarriers: ShipmentCarriers;
  origin: ShipmentLocation;
  destination: ShipmentLocation;
  lookups: {
    [key: string]: NameValuePair[]
  };
  quoteId: string;
  shipmentPriceType?: ShipmentPriceType;
  quote?: Quote;
  selectedQuoteId: string;
  shipmentReference: ShipmentReference;
  purchaseDetails: PurchaseDetails;
  error?: any;
  shipmentId: string;
  shipmentName: string;
  zingTrackingNumber: string;
  claim: Claim;
  claimDocuments: ClaimDocument[];
  billingRequestSaved?: boolean;
  billingRequestFailed?: boolean;
  approvalContact: ApprovalContact;
  boomerangDetails: BoomerangDetails;
  businessRules: Rules;
  internationalShipmentsData: InternationalShipmentData;
  internationalDetail: InternationalDetail;
  integratedAccountDetails: IntegratedAccountDetails;
  isInternational: boolean;
  isHoldForPickup: boolean;
  holdForPickupAddress: HoldForPickupAddress;
  noInsurance: boolean;
  insuranceOnlyTrackingNumber: string;
  insuranceOnly: boolean;
  hasApprovalCodeError: boolean;
  quoteError: string;
  isZeroDollarInsuranceWarningPrompted: boolean;
  isResidential: boolean;
  eStoreDetail: EStoreDetail;
  status?: ShipmentStatus;
  approvalData?: Approval;
  isDeclined?: boolean;
}

/**
 * Interface for the ShipmentDetails tab.
 */
export interface ShipmentDetails {
  cargoType: string;
  insurance: ShipmentInsurance;
  package: Package;
  deliverySecurity: DeliverySecurity;
  savedShipmentState?: SavedShipmentState;
  tariffCode?: string;
  isFedexOneRate?: boolean;
}

export interface ShipmentCarriers {
  carriers: Array<number>;
  deliverySpeed: DeliverySpeed;
  shippingTime?: ShippingTime;
}

export interface EStoreDetail {
  orderId: string;
  orderSource: OrderSource;
  storeType: StoreType;
  customersStoreRef: string;
  shipVia: string;
}

export enum OrderSource {
  Undefined = 0,
  Store = 1,
  Shiprush = 2,
  Integral = 3,
  File = 4,
  Shippo = 5,
  Api2Cart = 6
}

export enum StoreType {
  Undefined = 0,
  Ebay = 1,
  Amazon = 2,
  SolidCommerce = 3,
  Magento = 4,
  Volusion = 5,
  BigCommerce = 6,
  WooCommerce = 7,
  Shopify = 8,
  OpenCart = 9,
  UltraCart = 10,
  Contusult = 11,
  PayPal = 12,
  Yahoo = 13,
  ZenCart = 14,
  XCart = 15,
  OsCommerce = 16,
  Custom = 17,
  PostIn = 18,
  Etsy = 19,
  ChannelAdvisor = 20,
  CReloaded = 21,
  UberCart = 22,
  AspDotnetStoreFront = 23,
  ThreeDCart = 24,
  LoadedCommerce = 25,
  SellerCloud = 26,
  AceShop = 27,
  CsCart = 28,
  CubeCart = 29,
  DemandWare = 30,
  Ecwid = 31,
  Gambio = 32,
  Hybris = 33,
  Interspire = 34,
  JoeCart = 35,
  MijoShop = 36,
  Neto = 37,
  Oxid = 38,
  OsCmax = 39,
  Pinnacle = 40,
  PrestaShop = 41,
  SsPremium = 42,
  ShopWare = 43,
  SquareSpace = 44,
  TomatoCart = 45,
  VirtueMart = 46,
  WpeCommerce = 47,
  Walmart = 48,
  WebAsyst = 49,
  XtCommerce = 50,
  XtCommerceEveyton = 51,
  LightSpeed = 52,
  BatchFile = 1000,
  Royal4Wise = 1001,
  Ftp = 1002,
}


export interface Carriers {
  carrier: string;
}

export interface DeliverySpeed {
  deliveryType: string;
  services: Array<ServiceType>;
}

export interface ShippingTime {
  type: string;
  date: string;
}

export interface ShipmentReference {
  name: string;
  senderReference: string;
  receiverReference: string;
  includeSenderReference: boolean;
  includeReceiverReference: boolean;
  sealNumber: string;
  approvalCode: string;
}

export interface PurchaseDetails {
  id: number;
  trackingNumber: string;
  shipmentId: string;
  zingTrackingNumber: string;
  approvalRequired: boolean;
  hasInsurance: boolean;
  dateOfPurchase?: Date;
  isInternational?: boolean;
}

export interface BoomerangDetails {
  shipmentId: string;
  zingTrackingNumber: string;
}

//#endregion

//#region Initial shipping state

const emptyLocation = new ShipmentLocation({
  emailAddress: '',
  phone: {
    callingCode: '',
    number: ''
  },
  addressLine1: '',
  city: '',
  countryCode: '',
  state: '',
  postalCode: '',
});

export const initialState: ShippingState = {
  shippingPreset: null,
  shipmentActionType: ShipmentActionType.New,
  originalShipmentDetails: null,
  savingInProgress: false,
  cargoTypesLoaded: false,
  packageTypesLoaded: false,
  internationalShipmentsDataLoaded: false,
  shippingPresetsLoaded: false,
  shippingPresetPopulated: false,
  carriersLoaded: false,
  businessRulesLoaded: false,
  packageTypes: [],
  shippingPresets: [],
  carriers: [],
  origin: null,
  destination: null,
  cargo: [],
  shipmentDetails: null,
  shipmentCarriers: {
    shippingTime: {
      date: '',
      type: 'Today'
    },
    carriers: [],
    deliverySpeed: {
      deliveryType: '',
      services: []
    }
  },
  lookups: {},
  quoteId: '',
  shipmentPriceType: ShipmentPriceType.CanFileClaimTill30Days,
  quote: null,
  selectedQuoteId: '',
  shipmentReference: null,
  purchaseDetails: null,
  shipmentId: '',
  shipmentName: '',
  zingTrackingNumber: '',
  claim: null,
  claimDocuments: null,
  approvalContact: null,
  boomerangDetails: null,
  businessRules: null,
  internationalShipmentsData: null,
  internationalDetail: null,
  integratedAccountDetails: null,
  isInternational: false,
  isHoldForPickup: false,
  holdForPickupAddress: null,
  noInsurance: false,
  insuranceOnlyTrackingNumber: '',
  insuranceOnly: false,
  hasApprovalCodeError: false,
  quoteError: '',
  isZeroDollarInsuranceWarningPrompted: false,
  isResidential: false,
  eStoreDetail: null
};

//#endregion

//#region Selectors

const getShippingFeatureState = createFeatureSelector<ShippingState>('shipping');

export const getSelectedPreset = createSelector(
  getShippingFeatureState,
  state => state.shippingPreset
);

export const getOriginalShipmentDetails = createSelector(
  getShippingFeatureState,
  state => state.originalShipmentDetails
);

export const getShipmentActionType = createSelector(
  getShippingFeatureState,
  state => state.shipmentActionType
);

export const getSavingInProgress = createSelector(
  getShippingFeatureState,
  state => state.savingInProgress
);

export const getShipmentStatus = createSelector(
  getShippingFeatureState,
  state => state.status
);

export const getShipmentIsDeclined = createSelector(
  getShippingFeatureState,
  state => state.isDeclined
);

export const getApprovalComment = createSelector(
  getShippingFeatureState,
  state => state.approvalData?.comment
);

export const getIsReady = createSelector(
  getShippingFeatureState,
  ({ cargoTypesLoaded, packageTypesLoaded, lookups, businessRulesLoaded, internationalShipmentsDataLoaded }) => cargoTypesLoaded
    && packageTypesLoaded
    && lookups.hasOwnProperty('signaturetypes') && lookups['signaturetypes'].length > 0
    && lookups.hasOwnProperty('zipcodes') && lookups['zipcodes'].length > 0
    && businessRulesLoaded
    && internationalShipmentsDataLoaded
);

export const getCargo = createSelector(
  getShippingFeatureState,
  state => state.cargo
);

export const getLookup = createSelector(
  getShippingFeatureState,
  state => state.lookups
);

export const getPackageTypes = createSelector(
  getShippingFeatureState,
  state => state.packageTypes
);

export const getShippingPresets = createSelector(
  getShippingFeatureState,
  state => state.shippingPresets
);

export const getShipmentDetails = createSelector(
  getShippingFeatureState,
  state => state.shipmentDetails
);

export const getShipmentCarriers = createSelector(
  getShippingFeatureState,
  state => state.shipmentCarriers
);

export const getOriginLocation = createSelector(
  getShippingFeatureState,
  state => state.origin
);

export const getDestinationLocation = createSelector(
  getShippingFeatureState,
  state => state.destination
);

export const addressDetailsCompleteAndValid = createSelector(
  getShippingFeatureState,
  ({ origin, destination }) => origin && origin.valid && destination && destination.valid
);

export const originAddressValidAndComplete = createSelector(
  getShippingFeatureState,
  ({ origin }) => origin && origin.valid
);

export const destinationAddressValidAndComplete = createSelector(
  getShippingFeatureState,
  ({ destination }) => destination && destination.valid
);

export const getQuoteId = createSelector(
  getShippingFeatureState,
  state => state.quoteId
);

export const getShipmentPriceType = createSelector(
  getShippingFeatureState,
  state => state.shipmentPriceType
);

export const getSelectedQuoteId = createSelector(
  getShippingFeatureState,
  state => state.selectedQuoteId
);

export const getQuote = createSelector(
  getShippingFeatureState,
  state => state.quote
);

export const getShipmentReference = createSelector(
  getShippingFeatureState,
  state => state.shipmentReference
);

export const getPurchaseShipmentInfo = createSelector(
  getShippingFeatureState,
  state => state.purchaseDetails
);

export const getBoomerangDetails = createSelector(
  getShippingFeatureState,
  state => state.boomerangDetails
);

export const getPurchaseShipmentError = createSelector(
  getShippingFeatureState,
  state => state.error
);

export const getErrors = createSelector(
  getShippingFeatureState,
  state => state.error
);

export const getShipmentId = createSelector(
  getShippingFeatureState,
  state => state.shipmentId
);
export const getShipmentName = createSelector(
  getShippingFeatureState,
  state => state.shipmentName
);
export const getZingTrackingNumber = createSelector(
  getShippingFeatureState,
  state => state.zingTrackingNumber
);

export const getShippingDate = createSelector(
  getShippingFeatureState,
  state => state.shipmentCarriers.shippingTime
);

export const getClaim = createSelector(
  getShippingFeatureState,
  state => state.claim
);

export const getClaimDocuments = createSelector(
  getShippingFeatureState,
  state => state.claimDocuments
);

export const getBillingRequestSaved = createSelector(
  getShippingFeatureState,
  ({ billingRequestSaved }) => billingRequestSaved
);

export const getBillingRequestFailed = createSelector(
  getShippingFeatureState,
  ({ billingRequestFailed }) => billingRequestFailed
);

export const getApprovalContact = createSelector(
  getShippingFeatureState,
  state => state.approvalContact
);

export const getShippingPresetsLoaded = createSelector(
  getShippingFeatureState,
  state => state.shippingPresetsLoaded
);

export const getBusinessRules = createSelector(
  getShippingFeatureState,
  state => state.businessRules
);

export const getShippingPresetPopulatedSuccess = createSelector(
  getShippingFeatureState,
  state => state.shippingPresetPopulated
);

export const getInternationalShipmentData = createSelector(
  getShippingFeatureState,
  state => state.internationalShipmentsData
);

export const getIsInternational = createSelector(
  getShippingFeatureState,
  state => state.isInternational
);

export const getIsHoldForPickup = createSelector(
  getShippingFeatureState,
  state => state.isHoldForPickup
);

export const getHoldForPickupAddress = createSelector(
  getShippingFeatureState,
  state => state.holdForPickupAddress
);

export const getHasApprovalCodeError = createSelector(
  getShippingFeatureState,
  state => state.hasApprovalCodeError
);

export const getInsuranceOnly = createSelector(
  getShippingFeatureState,
  state => state.insuranceOnly
);

export const getInternationalDetail = createSelector(
  getShippingFeatureState,
  state => state.internationalDetail
);

export const getNoInsurance = createSelector(
  getShippingFeatureState,
  state => state.noInsurance
);

export const getInsuranceOnlyTrackingNumber = createSelector(
  getShippingFeatureState,
  state => state.insuranceOnlyTrackingNumber
);

export const getQuoteError = createSelector(
  getShippingFeatureState,
  state => state.quoteError
);

export const getZeroDollarInsuranceWarningPrompted = createSelector(
  getShippingFeatureState,
  state => state.isZeroDollarInsuranceWarningPrompted
);

export const getEStoreDetail = createSelector(
  getShippingFeatureState,
  state => state.eStoreDetail
);

export const getHighRiskDeliveryWarning = createSelector(
  getShippingFeatureState,
  state => state.destination.highRiskDeliveryWarning
);

export const getIntegratedAccountDetails = createSelector(
  getShippingFeatureState,
  state => state.integratedAccountDetails
);

//#endregion

//#region Action types reducer

export function shippingReducer(
  state = initialState,
  action: ShippingActions
): ShippingState {
  switch (action.type) {
    case ShippingActionTypes.SetInitialState: {
      return {
        ...state,
        ...action.payload
      };
    }
    case ShippingActionTypes.PopulateFromPreset:
      return mergeCurrentStateWithPreset(state, action.payload);
    case ShippingActionTypes.PopulateEditPresetFromPreset:
      return {
        ...state,
        shippingPreset: action.payload
      };
    case ShippingActionTypes.PopulateFromPresetSuccess:
      return {
        ...state,
        shippingPresetPopulated: true
      };
    case ShippingActionTypes.PopulateFromQuickQuote:
      return mergeCurrentStateWithQuickQuote(state, action.payload);
    case ShippingActionTypes.LoadCargo:
      return {
        ...state,
        cargoTypesLoaded: false
      };
    case ShippingActionTypes.LoadCargoSuccess:
      return {
        ...state,
        cargo: action.payload,
        cargoTypesLoaded: true
      };
    case ShippingActionTypes.LoadCargoFail:
      return {
        ...state,
        cargoTypesLoaded: false
      };
    case ShippingActionTypes.LoadLookup:
      return { ...state };
    case ShippingActionTypes.LoadLookupSuccess:
      return {
        ...state,
        lookups: {
          ...state.lookups,
          [action.payload.type]: action.payload.items
        }
      };
    case ShippingActionTypes.LoadPackageTypes:
      return {
        ...state,
        packageTypesLoaded: false
      };
    case ShippingActionTypes.LoadPackageTypesSuccess:
      return {
        ...state,
        packageTypes: action.payload,
        packageTypesLoaded: true
      };
    case ShippingActionTypes.LoadPackageTypesFail:
      return {
        ...state,
        packageTypesLoaded: false
      };
    case ShippingActionTypes.LoadInternationalShipmentsData:
      return {
        ...state,
        internationalShipmentsDataLoaded: false
      };
    case ShippingActionTypes.LoadInternationalShipmentsDataSuccess:
      return {
        ...state,
        internationalShipmentsData: action.payload,
        internationalShipmentsDataLoaded: true
      };
    case ShippingActionTypes.LoadInternationalShipmentsDataFail:
      return {
        ...state,
        internationalShipmentsDataLoaded: true,
        error: action.payload
      };
    case ShippingActionTypes.LoadIntegratedAccountDetailsSuccess:
      return {
        ...state,
        integratedAccountDetails: action.payload
      };
    case ShippingActionTypes.LoadIntegratedAccountDetailsFail:
      return {
        ...state,
        error: action.payload
      };
    case ShippingActionTypes.LoadCarriersSuccess:
      return {
        ...state,
        carriers: action.payload
      };
    case ShippingActionTypes.LoadCarriersFail:
      return {
        ...state,
        carriers: []
      };
    case ShippingActionTypes.SaveShipmentDetails:
      return {
        ...state,
        shipmentDetails: action.payload
      };
    case ShippingActionTypes.SaveOriginalShipmentDetails:
      return {
        ...state,
        originalShipmentDetails: action.payload
      };
    case ShippingActionTypes.SaveShipmentActionType:
      return {
        ...state,
        shipmentActionType: action.payload
      };
    case ShippingActionTypes.SaveShipmentCarriers:
      return {
        ...state,
        shipmentCarriers: action.payload
      };
    case ShippingActionTypes.SaveOriginLocation:
      return {
        ...state,
        origin: action.payload
      };
    case ShippingActionTypes.SaveDestinationLocation:
      return {
        ...state,
        destination: action.payload
      };
    case ShippingActionTypes.ResetShipment:
      return {
        ...initialState,
        // We don't want to clear types which are already loaded
        cargoTypesLoaded: state.cargoTypesLoaded,
        cargo: state.cargo,
        packageTypesLoaded: state.packageTypesLoaded,
        packageTypes: state.packageTypes,
        shippingPresetsLoaded: state.shippingPresetsLoaded,
        shippingPresets: state.shippingPresets,
        businessRulesLoaded: state.businessRulesLoaded,
        businessRules: state.businessRules,
        lookups: state.lookups,
        insuranceOnly: action.resetInsuranceOnlyFlag ?
          initialState.insuranceOnly : state.insuranceOnly
      };
    case ShippingActionTypes.SaveShipment:
      return {
        ...state,
        savingInProgress: true
      };
    case ShippingActionTypes.SaveShipmentSuccess:
      return {
        ...state,
        savingInProgress: false
      };
    case ShippingActionTypes.SaveShipmentFail:
      return {
        ...state,
        savingInProgress: false
      };
    case ShippingActionTypes.SaveQuoteId:
    case ShippingActionTypes.ProcessQuoteOptionsSuccess:
      return {
        ...state,
        quoteId: action.payload
      };
    case ShippingActionTypes.ProcessQuoteOptionsFail:
      return {
        ...state,
        quoteId: ''
      };
    case ShippingActionTypes.SaveSelectedQuote:
      return {
        ...state,
        selectedQuoteId: action.payload.quoteId,
        quote: action.payload.quote,
        shipmentPriceType: action.payload.shipmentPriceType
      };
    case ShippingActionTypes.SaveInternationalDetail:
      return {
        ...state,
        internationalDetail: action.payload
      };
    case ShippingActionTypes.SaveShipmentReference:
      return {
        ...state,
        shipmentReference: action.payload
      };
    case ShippingActionTypes.PurchaseShipment:
      return {
        ...state
      };
    case ShippingActionTypes.SavePurchaseDetails:
      return {
        ...state,
        purchaseDetails: {
          trackingNumber: action.payload.trackingNumber,
          shipmentId: action.payload.shipmentId,
          zingTrackingNumber: action.payload.zingTrackingNumber,
          id: action.payload.id,
          approvalRequired: action.payload.approvalRequired,
          hasInsurance: action.payload.hasInsurance,
          isInternational: action.payload.isInternational
        }
      };
    case ShippingActionTypes.SavedBoomerangShipment:
      return {
        ...state,
        originalShipmentDetails: null,
        boomerangDetails: {
          zingTrackingNumber: action.payload.zingTrackingNumber,
          shipmentId: action.payload.shipmentId,
        }
      };
    case ShippingActionTypes.PurchaseShipmentFail:
      return {
        ...state,
        error: action.payload,
        shipmentId: action.payload.error && action.payload.error.Id ?
          action.payload.error.Id[0] : state.shipmentId
      };
    case ShippingActionTypes.SaveShipmentId: {
      return {
        ...state,
        shipmentId: action.payload
      };
    }
    case ShippingActionTypes.SaveShipmentName: {
      return {
        ...state,
        shipmentName: action.payload
      };
    }
    case ShippingActionTypes.SaveShippingDate: {
      return {
        ...state,
        shipmentCarriers: {
          ...state.shipmentCarriers,
          shippingTime: action.payload
        }
      };
    }
    case ShippingActionTypes.SaveClaim:
      return {
        ...state,
        claim: action.payload
      };
    case ShippingActionTypes.SaveClaimDocuments:
      return {
        ...state,
        claimDocuments: action.payload
      };
    case ShippingActionTypes.ResetClaim:
      return {
        ...state,
        claim: null,
        claimDocuments: null
      };
    case ShippingActionTypes.SaveBillingReview: {
      return {
        ...state,
        savingInProgress: true,
        billingRequestSaved: false,
        billingRequestFailed: false
      };
    }
    case ShippingActionTypes.SaveBillingReviewSuccess: {
      return {
        ...state,
        savingInProgress: false,
        billingRequestSaved: true,
        billingRequestFailed: false
      };
    }
    case ShippingActionTypes.SaveBillingReviewFail: {
      return {
        ...state,
        savingInProgress: false,
        billingRequestSaved: false,
        billingRequestFailed: true
      };
    }
    case ShippingActionTypes.ClearBillingRequestNotifications: {
      return {
        ...state,
        savingInProgress: false,
        billingRequestSaved: false,
        billingRequestFailed: false
      };
    }
    case ShippingActionTypes.AddApprovalContact: {
      return {
        ...state,
        approvalContact: action.payload
      };
    }
    case ShippingActionTypes.LoadPresets: {
      return {
        ...state,
        shippingPresetsLoaded: false
      };
    }
    case ShippingActionTypes.LoadPresetsSuccess: {
      return {
        ...state,
        shippingPresets: [...action.payload],
        shippingPresetsLoaded: true
      };
    }
    case ShippingActionTypes.LoadPresetsFail: {
      return {
        ...state,
        shippingPresetsLoaded: false,
        error: action.payload
      };
    }
    case ShippingActionTypes.CreatePreset: {
      return {
        ...state,
        shippingPresetsLoaded: false,
        savingInProgress: true
      };
    }
    case ShippingActionTypes.CreatePresetSuccess: {
      return {
        ...state,
        savingInProgress: false
      };
    }
    case ShippingActionTypes.CreatePresetFail: {
      return {
        ...state,
        savingInProgress: false,
        error: action.payload,
        shippingPresetsLoaded: true
      };
    }
    case ShippingActionTypes.UpdatePreset: {
      return {
        ...state,
        shippingPresetsLoaded: false
      };
    }
    case ShippingActionTypes.UpdatePresetSuccess: {
      return {
        ...state,
        shippingPresetsLoaded: true,
        shippingPresets: [...state.shippingPresets.filter(x => x.id !== action.payload.id),
        action.payload]
      };
    }
    case ShippingActionTypes.UpdatePresetFail: {
      return {
        ...state,
        shippingPresetsLoaded: true,
        error: action.payload
      };
    }
    case ShippingActionTypes.DeletePreset: {
      return {
        ...state,
        shippingPresetsLoaded: false
      };
    }
    case ShippingActionTypes.DeletePresetSuccess: {
      return {
        ...state,
        shippingPresetsLoaded: true,
        shippingPresets: [...state.shippingPresets.filter(x => x.id !== action.payload.shippingPresetId)]
      };
    }
    case ShippingActionTypes.DeletePresetFail: {
      return {
        ...state,
        shippingPresetsLoaded: true,
        error: action.payload
      };
    }
    case ShippingActionTypes.LoadRulesSuccess: {
      return {
        ...state,
        businessRules: action.payload,
        businessRulesLoaded: true
      };
    }
    case ShippingActionTypes.LoadRulesFail: {
      return {
        ...state,
        error: action.payload
      };
    }
    case ShippingActionTypes.ClearError: {
      return {
        ...state,
        error: null
      };
    }
    case ShippingActionTypes.SaveIsInternational:
      return {
        ...state,
        isInternational: action.payload
      };
    case ShippingActionTypes.SaveIsHoldForPickup:
      return {
        ...state,
        isHoldForPickup: action.payload
      };
    case ShippingActionTypes.SaveHoldForPickupAddress:
      return {
        ...state,
        holdForPickupAddress: action.payload
      };
    case ShippingActionTypes.SaveHasApprovalCodeError:
      return {
        ...state,
        hasApprovalCodeError: action.payload
      };
    case ShippingActionTypes.SaveNoInsurance:
      return {
        ...state,
        noInsurance: action.payload
      };
    case ShippingActionTypes.SaveInsuranceOnlyTrackingNumber:
      return {
        ...state,
        insuranceOnlyTrackingNumber: action.payload
      };
    case ShippingActionTypes.SaveInsuranceOnly:
      return {
        ...state,
        insuranceOnly: action.payload
      };
    case ShippingActionTypes.SaveQuoteError:
      return {
        ...state,
        quoteError: action.payload
      };
    case ShippingActionTypes.SaveZeroDollarInsuranceWarningPrompted:
      return {
        ...state,
        isZeroDollarInsuranceWarningPrompted: action.payload
      };
    case ShippingActionTypes.LoadEStoreData:
      return {
        ...state,
        eStoreDetail: action.payload
      };
    default:
      return state;
  }
}

//#endregion


function mergeCurrentStateWithPreset(
  currState: ShippingState,
  shippingPreset: ShippingPreset
): ShippingState {
  const insurance: ShipmentInsurance = currState.shipmentDetails && currState.shipmentDetails.insurance || {
    insuredValue: null,
    excludePolicyCover: false,
    codSelected: false,
    thirdPartySelected: false,
    isMailRegistered: false,
    invoiceValue: null,
    isSaturdayDelivery: false
  };

  let origin: ShipmentLocation = null;
  let destination: ShipmentLocation = null;
  if (shippingPreset.origin) {
    const originPhone = <PhoneNumber>formatPhoneNumber(shippingPreset.origin.phoneNumber, false);
    origin = new ShipmentLocation(shippingPreset.origin);
    origin.phone = {
      callingCode: originPhone.countryCode,
      number: originPhone.number
    };
    changeNullWithEmptyString(origin);
  }

  if (shippingPreset.destination) {
    const destinationPhone = <PhoneNumber>formatPhoneNumber(shippingPreset.destination.phoneNumber, false);
    destination = new ShipmentLocation(shippingPreset.destination);
    destination.phone = {
      callingCode: destinationPhone.countryCode,
      number: destinationPhone.number
    };
    changeNullWithEmptyString(destination);
  }

  return {
    ...currState,
    ...{
      shippingPresetPopulated: false,
      shippingPreset: shippingPreset,
      origin,
      destination,
      shipmentDetails: {
        ...currState.shipmentDetails,
        ...{
          cargoType: shippingPreset.cargoId || '',
          package: {
            packageDetails: shippingPreset.package.packageDetails,
            packagingTypes: shippingPreset.package.packagingTypes,
            weights: shippingPreset.package.weights.map(x => x.value),
            length: shippingPreset.package.dimensions.length,
            width: shippingPreset.package.dimensions.width,
            height: shippingPreset.package.dimensions.height
          },
          deliverySecurity: {
            signatures: shippingPreset.signatureOptions ?
              shippingPreset.signatureOptions.map(x => x.toString()) : []
          },
          insurance
        }
      },
      shipmentCarriers: {
        ...currState.shipmentCarriers,
        ...{
          carriers: shippingPreset.carriers,
          deliverySpeed: {
            deliveryType: '',
            services: shippingPreset.services || []
          }
        }
      }
    }
  };
}

function mergeCurrentStateWithQuickQuote(
  currState: ShippingState,
  quickQuoteData: QuickQuote
): ShippingState {
  const {
    origin,
    destination,
    declaredValue,
    weight,
    signature,
    cargoType,
    carriers,
    services,
    package: packageInfo
  } = quickQuoteData;

  return {
    ...currState,
    ...{
      origin: new ShipmentLocation({
        countryCode: 'USA',
        state: origin.state,
        postalCode: origin.postalCode,
      }),
      destination: new ShipmentLocation({
        countryCode: 'USA',
        state: destination.state,
        postalCode: destination.postalCode,
      }),
      shipmentDetails: {
        cargoType: cargoType,
        deliverySecurity: {
          signatures: [signature.toString()]
        },
        insurance: {
          insuredValue: declaredValue,
          excludePolicyCover: false,
          codSelected: false,
          thirdPartySelected: false,
          isMailRegistered: false,
          isSaturdayDelivery: false
        },
        package: {
          packageDetails: packageInfo ? packageInfo.packageDetails : null,
          packagingTypes: packageInfo ? packageInfo.packagingTypes : null,
          weights: weight.length > 0 ? weight.map(x => x.value) : [null, null],
          length: null,
          width: null,
          height: null
        }
      },
      shipmentCarriers: {
        ...currState.shipmentCarriers,
        ...{
          carriers: [...carriers],
          deliverySpeed: {
            deliveryType: '',
            services: [...services]
          }
        }
      }
    }
  };
}

function changeNullWithEmptyString(entity: any) {
  for (const propName in entity) {
    if (entity[propName] === null) {
      entity[propName] = '';
    }
  }
}
