import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'z-participant-agreement',
  styleUrls: ['./participant-agreement.component.scss'],
  templateUrl: './participant-agreement.component.html'
})
export class ParticipantAgreementComponent implements OnInit {

  sanitizedUrl: any;
  readonly updatedDocumentUrl: string = '../../../../assets/PDF Zing Participant Agreement - August 2024.pdf';
  readonly documentUrl: string = '../../../../assets/Zing Participant Agreement 01.18.2023.pdf';
  nonJewelerFeatureEnabled = environment.nonJewelerFeatureEnabled;

  constructor(
    private domSanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.sanitizedUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.nonJewelerFeatureEnabled ? 
      this.updatedDocumentUrl : this.documentUrl);    
  }

}
