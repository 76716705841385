import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { AuthService } from '../modules/core/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class GuestGuard implements CanActivate {

  constructor(
    public router: Router,
    private oidcSecurityService: OidcSecurityService,
    private authService: AuthService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (state.url.includes('tutorial')) {
      return true;
    }

    return this.oidcSecurityService.checkAuth()
      .pipe(
        map((res: LoginResponse) => res.isAuthenticated),
        map(isAuthorized => {
          if (isAuthorized) {
            this.router.navigateByUrl('/dashboard');
            return false;
          } else {
            // silent renew is not called in oidc library unless you log in, so this checks for auto login
            if (!this.authService.logoffRunning) {
              // stop re-attempt of logging in constantly
              let reTryAttempt = Number(sessionStorage.getItem('retryAutologin'));

              reTryAttempt = reTryAttempt > 0 ? ++reTryAttempt : 1;
              sessionStorage.setItem('retryAutologin', reTryAttempt.toString());
              if (reTryAttempt > 2) {
                return true;
              }
              if (localStorage.getItem('oidcIsAuthenticated') === 'true') {
                this.router.navigateByUrl('/dashboard');
              }
            }
          }
          return true;
        }),
        take(1)
      );
  }

}
