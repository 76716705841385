/* eslint-disable max-len */
import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbAccordionModule, NgbDateParserFormatter, NgbModule, NgbPopoverModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AddressDetailsComponent } from './components/address-details/address-details.component';
import { AuthMethodsComponent } from './components/auth-methods/auth-methods.component';
import { CodeInputsComponent } from './components/code-inputs/code-inputs.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { PasswordFieldComponent } from './components/password-field/password-field.component';
import { PasswordStatisticsComponent } from './components/password-statistics/password-statistics.component';
import { SpinnerModule } from './components/spinner/spinner.module';
import { AnalyticsDirective } from './directives/analytics.directive';
import { CreditCardAnyFormatDirective } from './directives/credit-card-format.directive';
import { DecimalDigitsOnlyDirective } from './directives/decimal-digits-only.directive';
import { ForbiddenWordsDirective } from './directives/forbidden-words.directive';
import { DigitOnlyDirective } from './directives/only-digits.directive';
import { AddressPipe } from './pipes/address.pipe';
import { KeysPipe } from './pipes/keys.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { ReversePipe } from './pipes/reverse.pipe';
import { TimeAgoPipe } from './pipes/timeago.pipe';
import { services } from './services';

import { RouterModule } from '@angular/router';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NgSelectModule } from '@ng-select/ng-select';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';
import { CountryCodeToNamePipe } from '../marketplace/modules/lab-grown-diamonds/pipes/country-code-to-name.pipe';
import { DateRangeComponent } from './components/date-range/date-range.component';
import {
  FedexIntegrationModalComponent
} from '../membership/modules/shipping/components/fedex-integration-modal/fedex-integration-modal.component';
import { MergeAccountsModalComponent } from '../membership/modules/shipping/components/merge-accounts-modal/merge-accounts-modal.component';
import { ProcessingRequestModalComponent } from '../membership/modules/shipping/components/processing-request-modal/processing-request-modal.component';
import { ShipmentTrackerComponent } from '../membership/modules/shipping/components/shipment-tracker/shipment-tracker.component';
import { TextSearchSummaryFilterComponent } from '../membership/modules/shipping/components/text-search-summary-filter/text-search-summary-filter.component';
import { TrackingActivityComponent } from '../membership/modules/shipping/components/tracking-activity/tracking-activity.component';
import {
  TrackingShipmentDetailsComponent
} from '../membership/modules/shipping/components/tracking-shipment-details/tracking-shipment-details.component';
import { UPSIntegrationModalComponent } from '../membership/modules/shipping/components/ups-integration-modal/ups-integration-modal.component';
import { PopupModalsModule } from '../popup-modals/popup-modals.module';
import { AddressInvalidConfirmationComponent } from './components/address-invalid-confirmation/address-invalid-confirmation.component';
import { BackLinkComponent } from './components/back-link/back-link.component';
import { BannerAdvertisementComponent } from './components/banner-advertisement/banner-advertisement.component';
import { BannersComponent } from './components/banners/banners.component';
import { CharacterCounterComponent } from './components/character-counter/charcter-counter.component';
import { CollapseComponent } from './components/collapse/collapse.component';
import { DateRangeControlComponent } from './components/date-range-control/date-range-control.component';
import { FooterComponent } from './components/footer/footer.component';
import { GridContainerComponent } from './components/grid-container/grid-container.component';
import { IdleWarningComponent } from './components/idle-warning/idle-warning.component';
import { MessageSpinnerComponent } from './components/message-spinner/message-spinner.component';
import { NewOrderNavigationComponent } from './components/new-order-navigation/new-order-navigation.component';
import { OrderTrackerComponent } from './components/order-tracker/order-tracker.component';
import { PeriodSelectorComponent } from './components/period-selector/period-selector.component';
import { RadioButtonImageComponent } from './components/radio-button-image/radio-button-image.component';
import { ReportingStatusComponent } from './components/reporting-satus/reporting-status.component';
import { ShipmentAddressInfoComponent } from './components/shipment-address-info/shipment-address-info.component';
import { TimePickerComponent } from './components/timepicker/timepicker.component';
import { TooltipDataelementComponent } from './components/tooltip-dataelement/tooltip-dataelement.component';
import { TooltipDatalistComponent } from './components/tooltip-datalist/tooltip-datalist.component';
import { UploadDropzoneComponent } from './components/upload-dropzone/upload-dropzone.component';
import { WidgetAdvertisementComponent } from './components/widget-advertisement/widget-advertisement.component';
import { ZingDashboardFooterComponent } from './components/zing-dashboard-footer/zing-dashboard-footer.component';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { BackgroundImageLoadedDirective } from './directives/background-image-loaded.directive';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { DropdownAllDirective } from './directives/dropdown-all.directive';
import { FormatDecimalsDirective } from './directives/format-decimals.directive';
import { KendoFocusableDirective } from './directives/kendo-focusable.directive';
import { LimitLengthDirective } from './directives/limit-length.directive';
import { OptionalLeadingZeroDecimalDirective } from './directives/optional-leading-zero-decimal.directive';
import { ZeroValueDirective } from './directives/zero-value.directive';
import { CapitalizeFirstLetter } from './pipes/capitalize-first-letter.pipe';
import { CurrencyExchangePipe } from './pipes/currency-exchange.pipe';
import { FileToImagePipe } from './pipes/file-to-image.pipe';
import { IsValidDatePipe } from './pipes/isvaliddate.pipe';
import { MomentDatetimePipe } from './pipes/momentdatetime.pipe';
import { MomentDatetimeUTCPipe } from './pipes/momentdatetimeUTC.pipe';
import { PagerInfoPipe } from './pipes/pager-info.pipe';
import { PhoneMaskPipe } from './pipes/phonemask.pipe';
import { PostalCodeMaskPipe } from './pipes/postalcodemask.pipe';
import { BannerService } from './services/banner.service';
import { CustomDateParserFormatter } from './services/date-formatter';
import { TruncateStringPipe } from './pipes/truncate-string.pipe';
import { ForbiddenModalComponent } from '../popup-modals/components/forbidden-modal/forbidden-modal.component';
import { MatchHeightDirective } from './directives/match-height.directive';
import { VideoTutorialComponent } from './components/video-tutorial/video-tutorial.component';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgbPopoverModule,
    NgbAccordionModule,
    AlertModule.forRoot(),
    CollapseModule.forRoot(),
    NgbTypeaheadModule,
    TooltipModule.forRoot(),
    TranslateModule.forChild(),
    ModalModule.forRoot(),
    CreditCardDirectivesModule,
    NgIdleKeepaliveModule.forRoot(),
    RouterModule,
    NgSelectModule,
    DropDownsModule,
    SpinnerModule,
    NgxMaskDirective,
    NgxMaskPipe,
    PopupModalsModule
  ],
  declarations: [
    AuthMethodsComponent,
    CollapseComponent,
    DateRangeComponent,
    AddressDetailsComponent,
    CodeInputsComponent,
    DigitOnlyDirective,
    DecimalDigitsOnlyDirective,
    OptionalLeadingZeroDecimalDirective,
    AnalyticsDirective,
    AutoFocusDirective,
    PasswordStatisticsComponent,
    ConfirmModalComponent,
    AddressPipe,
    KeysPipe,
    ReversePipe,
    PhonePipe,
    PagerInfoPipe,
    CurrencyExchangePipe,
    CountryCodeToNamePipe,
    CapitalizeFirstLetter,
    CreditCardAnyFormatDirective,
    ClickOutsideDirective,
    ForbiddenWordsDirective,
    ZeroValueDirective,
    DropdownAllDirective,
    TimeAgoPipe,
    PasswordFieldComponent,
    BackLinkComponent,
    TooltipDatalistComponent,
    TooltipDataelementComponent,
    GridContainerComponent,
    PeriodSelectorComponent,
    FormatDecimalsDirective,
    BackgroundImageLoadedDirective,
    PhoneMaskPipe,
    PostalCodeMaskPipe,
    ReportingStatusComponent,
    ShipmentTrackerComponent,
    TrackingActivityComponent,
    TrackingShipmentDetailsComponent,
    BannersComponent,
    IdleWarningComponent,
    KendoFocusableDirective,
    AddressInvalidConfirmationComponent,
    ShipmentAddressInfoComponent,
    MergeAccountsModalComponent,
    ZingDashboardFooterComponent,
    FooterComponent,
    DateRangeControlComponent,
    OrderTrackerComponent,
    MomentDatetimePipe,
    IsValidDatePipe,
    TimePickerComponent,
    FedexIntegrationModalComponent,
    UploadDropzoneComponent,
    DragAndDropDirective,
    BannerAdvertisementComponent,
    WidgetAdvertisementComponent,
    MomentDatetimeUTCPipe,
    LimitLengthDirective,
    MessageSpinnerComponent,
    TextSearchSummaryFilterComponent,
    FileToImagePipe,
    UPSIntegrationModalComponent,
    RadioButtonImageComponent,
    ProcessingRequestModalComponent,
    CharacterCounterComponent,
    NewOrderNavigationComponent,
    TruncateStringPipe,
    ForbiddenModalComponent,
    VideoTutorialComponent,
    MatchHeightDirective
  ],
  providers: [
    ...services,
    BannerService,
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    DecimalPipe,
    provideEnvironmentNgxMask()
  ],
  exports: [
    AlertModule,
    CommonModule,
    FormsModule,
    NgbTypeaheadModule,
    TooltipModule,
    CollapseModule,
    ReactiveFormsModule,
    TranslateModule,
    AuthMethodsComponent,
    CollapseComponent,
    AddressDetailsComponent,
    CodeInputsComponent,
    DateRangeComponent,
    TextSearchSummaryFilterComponent,
    DigitOnlyDirective,
    DecimalDigitsOnlyDirective,
    AutoFocusDirective,
    OptionalLeadingZeroDecimalDirective,
    PasswordStatisticsComponent,
    ConfirmModalComponent,
    ProcessingRequestModalComponent,
    AddressPipe,
    KeysPipe,
    ReversePipe,
    PhonePipe,
    PagerInfoPipe,
    TimeAgoPipe,
    CurrencyExchangePipe,
    CountryCodeToNamePipe,
    CapitalizeFirstLetter,
    AnalyticsDirective,
    CreditCardAnyFormatDirective,
    ClickOutsideDirective,
    ForbiddenWordsDirective,
    ZeroValueDirective,
    DropdownAllDirective,
    BackgroundImageLoadedDirective,
    PasswordFieldComponent,
    BackLinkComponent,
    TooltipDatalistComponent,
    TooltipDataelementComponent,
    GridContainerComponent,
    PasswordFieldComponent,
    CreditCardDirectivesModule,
    PeriodSelectorComponent,
    FormatDecimalsDirective,
    PhoneMaskPipe,
    PostalCodeMaskPipe,
    FileToImagePipe,
    ReportingStatusComponent,
    ShipmentTrackerComponent,
    TrackingActivityComponent,
    TrackingShipmentDetailsComponent,
    BannersComponent,
    IdleWarningComponent,
    KendoFocusableDirective,
    ShipmentAddressInfoComponent,
    NgbAccordionModule,
    NgbPopoverModule,
    ZingDashboardFooterComponent,
    FooterComponent,
    DateRangeControlComponent,
    OrderTrackerComponent,
    MomentDatetimePipe,
    IsValidDatePipe,
    TimePickerComponent,
    UploadDropzoneComponent,
    DragAndDropDirective,
    BannerAdvertisementComponent,
    WidgetAdvertisementComponent,
    MomentDatetimeUTCPipe,
    LimitLengthDirective,
    MessageSpinnerComponent,
    RadioButtonImageComponent,
    SpinnerModule,
    CharacterCounterComponent,
    NewOrderNavigationComponent,
    NgxMaskDirective,
    NgxMaskPipe,
    TruncateStringPipe,
    ForbiddenModalComponent,
    VideoTutorialComponent,
    MatchHeightDirective
  ]
})
export class SharedModule { }
