import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RegisterActions, RegisterActionTypes } from '../actions/register.actions';
import * as fromRoot from '../../../../../../store/root.state';
import { SecurityQuestion } from '../../../../../shared/models/security-question';
import { Invite, getComanyDetailsState } from '../../../models/invite';
import { AddressValidation } from '../../../../../shared/enums/address-validation';
import { EmailConsent } from '../../../models';
import { emptyAGSPhoneNumber } from '../../../../../shared/constants';
import { RegistrationSource } from '../../../../../membership/modules/account/models/person-source.enum';
import { BusinessCategory } from '../../../../../membership/modules/account/models/business-category';

export interface ZingState extends fromRoot.ZingState {
  registerState: RegisterState;
}

export interface RegisterState {
  error?: any;
  isLoading?: boolean;
  inviteDetails?: Invite;
  currentStep: string;
  companySearchResults: any[];
  companySearchFailed: boolean;
  personalDetails: PersonalDetails;
  companyDetails: CompanyDetails;
  partnerDetails?: PartnerDetails;
  securityOptions: SecurityOptions;
  securityQuestions: Array<SecurityQuestion>;
  registrationCompleted: boolean;
  verification: any;
  newRegister: any;
  businessCategories: Array<BusinessCategoryWithClassification>;
  displayRegistrationBanner: boolean;
}

export interface PersonalDetails {
  invited: boolean;
  userId?: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  email: string;
  countryCode: string;
  phone: string;
  clientId: string;
  emailConsent: EmailConsent;
  source?: RegistrationSource;
}

export interface CompanyDetails {
  companyName?: string;
  city?: string;
  feinId?: string;
  addressLine1?: string;
  stateName?: string;
  postCode?: string;
  isOwner?: boolean;
  checked?: boolean;
  existsOnPlatform?: boolean;
  addressLine2?: string;
  countryId?: string;
  stateId?: string;
  addressValidation?: AddressValidation;
  countryCode?: string;
  phone?: string;
  businessNumber?: string;
  primarySIC?: string;
  secondarySIC?: string;
  primaryNAICS?: string;
  secondaryNAICS?: string;
  businessCategoryCode?: string;
  businessCategoryOther?: string;
  experianClassificationCode?: string;
}

export enum AffiliationType {
  NONE = 0,
  AGS = 1,
  GemAndJewel = 2
}
export interface PartnerDetails {
  affiliationType?: AffiliationType;
  agsMembershipId: string;
  agsMembershipMasterId?: string;
  agsMembershipStartDate?: Date;
  agsMembershipEndDate?: Date;
}
export interface SecurityOptions {
  password: string;
  confirmPassword: string;
  selectedSecurityQuestions: Array<{
    questionId: string,
    questionText: string,
    answerText: string
  }>;
}

export interface BusinessCategoryWithClassification extends BusinessCategory {
  classificationCode: string;
}
export const initialState: RegisterState = {
  currentStep: 'PersonalDetails',
  companySearchResults: [],
  companySearchFailed: false,
  personalDetails: {
    invited: false,
    userId: '',
    firstName: '',
    lastName: '',
    middleName: '',
    email: '',
    countryCode: '+1',
    phone: '',
    clientId: '',
    emailConsent: EmailConsent.No,
    source: RegistrationSource.Zing
  },
  companyDetails: {
    companyName: '',
    city: '',
    stateId: '',
    feinId: '',
    addressLine1: '',
    stateName: '',
    postCode: '',
    isOwner: false,
    checked: false,
    existsOnPlatform: false,
    countryCode: '+1',
    phone: '',
    businessNumber: '',
    primarySIC: '',
    secondarySIC: '',
    primaryNAICS: '',
    secondaryNAICS: ''
  },
  securityOptions: {
    password: '',
    confirmPassword: '',
    selectedSecurityQuestions: []
  },
  securityQuestions: [],
  registrationCompleted: false,
  verification: {},
  newRegister: {
    bin: '',
    businessName: '',
    city: '',
    id: '',
    isHeadquarters: false,
    phoneNumber: '',
    state: '',
    street: '',
    taxId: null,
    zip: '',
    zipExtension: null,
    stateName: '',
    primarySIC: '',
    secondarySIC: '',
    primaryNAICS: '',
    secondaryNAICS: ''
  },
  businessCategories: [],
  displayRegistrationBanner: false
};

const getRegisterFeatureState = createFeatureSelector<RegisterState>('register');

export const getIsLoading = createSelector(
  getRegisterFeatureState,
  state => state.isLoading
);

export const getError = createSelector(
  getRegisterFeatureState,
  state => state.error
);

export const getCurrentStep = createSelector(
  getRegisterFeatureState,
  state => state.currentStep
);

export const getInviteDetails = createSelector(
  getRegisterFeatureState,
  state => state.inviteDetails
);

export const getCompanySearchResults = createSelector(
  getRegisterFeatureState,
  state => state.companySearchResults
);

export const getSearchFailed = createSelector(
  getRegisterFeatureState,
  state => state.companySearchFailed
);

export const getPersonalDetails = createSelector(
  getRegisterFeatureState,
  state => state.personalDetails
);

export const getRegistrationSource = createSelector(
  getRegisterFeatureState,
  state => state.personalDetails.source
);

export const getPartnerDetails = createSelector(
  getRegisterFeatureState,
  state => state.partnerDetails
);

export const getCompanyDetails = createSelector(
  getRegisterFeatureState,
  state => state.companyDetails
);

export const getSecurityOptions = createSelector(
  getRegisterFeatureState,
  state => state.securityOptions
);

export const getSecurityQuestions = createSelector(
  getRegisterFeatureState,
  state => state.securityQuestions
);

export const getRegistrationCompleted = createSelector(
  getRegisterFeatureState,
  state => state.registrationCompleted
);

export const getRegisterNewCompany = createSelector(
  getRegisterFeatureState,
  state => state.newRegister
);

export const getDisplayRegistrationBanner = createSelector(
  getRegisterFeatureState,
  state => state.displayRegistrationBanner
);

export const getBusinessCategories = createSelector(
  getRegisterFeatureState,
  state => state.businessCategories
);

export function registerReducer(
  state = initialState,
  action: RegisterActions): RegisterState {
  switch (action.type) {
    case RegisterActionTypes.ChangeCurrentStep:
      return {
        ...state,
        currentStep: action.payload,
        companySearchResults: []
      };
    case RegisterActionTypes.VerifyInviteCode:
      return {
        ...state,
        isLoading: true,
      };
    case RegisterActionTypes.VerifyInviteCodeSuccess:
      return {
        ...state,
        isLoading: false,
        error: null,
        personalDetails: {
          ...state.personalDetails,
          invited: true,
          email: action.payload.email,
          firstName: action.payload.personInfo.firstName,
          lastName: action.payload.personInfo.lastName,
          middleName: action.payload.personInfo.middleName,
          phone: action.payload.personInfo.cellPhone,
          countryCode: (action.payload.personInfo.countryCode != null &&
            action.payload.personInfo.countryCode !== '') ? action.payload.personInfo.countryCode : '+1',
        },
        companyDetails: {
          ...getComanyDetailsState(action.payload.company),
          countryCode: (action.payload.company.countryCode != null &&
            action.payload.company.countryCode !== '') ? action.payload.company.countryCode : '+1',
          checked: true,
        },
        inviteDetails: action.payload
      };
    case RegisterActionTypes.VerifyInviteCodeFail:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case RegisterActionTypes.LoadSecurityQuestions:
      return { ...state };
    case RegisterActionTypes.LoadSecurityQuestionsSuccess:
      return {
        ...state,
        error: null,
        securityQuestions: [...action.payload]
      };
    case RegisterActionTypes.LoadSecurityQuestionsFail:
      return { ...state };
    case RegisterActionTypes.SavePersonalDetails:
      return {
        ...state,
        personalDetails: { ...action.payload }
      };
    case RegisterActionTypes.SaveCompanyDetails:
      return {
        ...state,
        companyDetails: {
          ...state.companyDetails,
          ...action.payload
        }
      };
    case RegisterActionTypes.SaveSecurityOptions:
      return {
        ...state,
        securityOptions: { ...action.payload }
      };
    case RegisterActionTypes.CheckCompanyExists:
      return {
        ...state,
        isLoading: true
      };
    case RegisterActionTypes.CheckCompanyExistsSuccess:
      return {
        ...state,
        isLoading: false,
        error: null,
        companyDetails: {
          ...state.companyDetails,
          existsOnPlatform: action.payload,
          checked: false
        }
      };
    case RegisterActionTypes.CheckCompanyExistsFail:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case RegisterActionTypes.SearchCompanies:
      return {
        ...state,
        isLoading: true
      };
    case RegisterActionTypes.SearchCompaniesSuccess:
      const results = action.payload.filter(c => c['taxId']);

      return {
        ...state,
        isLoading: false,
        error: null,
        companySearchResults: results,
        companySearchFailed: results && results.length === 0
      };
    case RegisterActionTypes.SearchCompaniesFail:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        companySearchFailed: true
      };
    case RegisterActionTypes.CreateNewUser:
      return {
        ...state,
        isLoading: true
      };
    case RegisterActionTypes.CreateNewUserSuccess:
      return {
        ...state,
        isLoading: false,
        error: null,
        registrationCompleted: true,
        inviteDetails: null,
        personalDetails: {
          ...state.personalDetails,
          userId: action.payload.id,
          source: action.payload.source
        }
      };
    case RegisterActionTypes.CreateNewUserFail:
      return {
        ...state,
        isLoading: false,
        error: { code: 'RegistrationFailed' }
      };
    case RegisterActionTypes.CreateNewUserViaInvite:
      return {
        ...state,
        isLoading: true
      };
    case RegisterActionTypes.CreateNewUserViaInviteSuccess:
      return {
        ...state,
        isLoading: false,
        error: null,
        registrationCompleted: true,
        inviteDetails: null,
        personalDetails: {
          ...state.personalDetails,
          userId: action.payload.id,
          source: action.payload.source
        }
      };
    case RegisterActionTypes.CreateNewUserViaInviteFail:
      return {
        ...state,
        isLoading: false,
        error: { code: 'RegistrationViaInviteFailed' }
      };
    case RegisterActionTypes.LoadExistingUserDetails:
      return {
        ...state,
        isLoading: true
      };
    case RegisterActionTypes.LoadExistingUserDetailsSuccess:
      return {
        ...state,
        isLoading: false,
        error: null,
        personalDetails: {
          ...state.personalDetails,
          invited: false,
          email: action.payload.email,
          firstName: action.payload.personInfo.firstName,
          lastName: action.payload.personInfo.lastName,
          middleName: action.payload.personInfo.middleName,
          phone: action.payload.personInfo.cellPhone,
          countryCode: (action.payload.personInfo.countryCode != null &&
            action.payload.personInfo.countryCode !== '') ? action.payload.personInfo.countryCode : '+1',
          clientId: action.payload.clientId
        },
        companyDetails: {
          ...getComanyDetailsState(action.payload.company),
          countryCode: (action.payload.company.countryCode != null &&
            action.payload.company.countryCode !== '') ? action.payload.company.countryCode : '+1',
          checked: false,
          isOwner: false
        }
      };
    case RegisterActionTypes.LoadExistingUserDetailsFail:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case RegisterActionTypes.LoadPartnerUserDetails:
      return {
        ...state,
        isLoading: true
      };
    case RegisterActionTypes.LoadPartnerUserDetailsSuccess:
      return {
        ...state,
        isLoading: false,
        error: null,
        personalDetails: {
          ...state.personalDetails,
          email: action.payload.email,
          firstName: action.payload.personInfo.firstName,
          lastName: action.payload.personInfo.lastName,
          phone: action.payload.personInfo.cellPhone && action.payload.personInfo.cellPhone !== emptyAGSPhoneNumber ? action.payload.personInfo.cellPhone.replace(/-/g, '') : '',
        },
        partnerDetails: {
          affiliationType: action.payload.affiliationType,
          agsMembershipMasterId: action.payload.agsMembershipMasterId,
          agsMembershipId: action.payload.agsMembershipId,
          agsMembershipEndDate: action.payload.agsMembershipStartDate,
          agsMembershipStartDate: action.payload.agsMembershipEndDate,
        },
        companyDetails: {
          ...getComanyDetailsState(action.payload.companyInfo),
          countryCode: (action.payload.companyInfo.countryCode != null &&
            action.payload.companyInfo.countryCode !== '') ? action.payload.companyInfo.countryCode : '+1',
          checked: true,
          isOwner: false
        }
      };
    case RegisterActionTypes.LoadPartnerUserDetailsFail:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case RegisterActionTypes.SaveNewRegisterCompany:
      return {
        ...state,
        newRegister: {
          ...action.payload,
          countryCode: (action.payload.countryCode != null &&
            action.payload.countryCode !== '') ? action.payload.countryCode : '+1',
        }
      };

    case RegisterActionTypes.LoadBusinessClassifications:
      return {
        ...state
      };
    case RegisterActionTypes.LoadBusinessClassificationsSuccess:
      return {
        ...state,
        businessCategories: action.payload
      };
    case RegisterActionTypes.LoadBusinessClassificationsFail:
      return {
        ...state
      };

    case RegisterActionTypes.DisplayRegistrationBanner:
      return {
        ...state,
        displayRegistrationBanner: action.payload
      };
    default:
      return state;
  }
}
