import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MixPanelService {
  constructor() {
    mixpanel.init(environment.mixPanelApiKey, {
      debug: true,
      persistence: 'localStorage',
    });
  }

  sendTrackingRequest(eventName: string, eventObject: object){
    mixpanel.track(
      eventName, eventObject
    );
  }
}
