import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ApprovalStatus } from '../../membership/modules/account/enums/approval-status';
import { PersonRole } from '../../membership/modules/account/models/person-role.model';
import { Person } from '../../membership/modules/account/models/person.model';
import { ServicePermission, ShipmentServicePermission } from '../../membership/modules/account/models/service-permission';
import { UserRoleCode } from '../../membership/modules/account/models/user-role-code.enum';
import { BusinessServiceType } from '../enums/business-service-type';
import { BaseHttpService } from './base-http.service';
import { PartnerService } from './partner.service';
import { ThemeService } from './theme.service';

@Injectable()
export class ProfileService extends BaseHttpService<Person> {

  public person: Person;
  public forcePersonLoad = false;
  public roles: UserRoleCode[] = [];

  private personLoaded$ = new BehaviorSubject(false);
  readonly personLoadedObs$ = this.personLoaded$.asObservable();
  public changeBannerTab$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private themeService: ThemeService,
    private partnerService: PartnerService
  ) {
    super(http);
    this.apiDomain = environment.membershipApiURL;
    this.endpoint = 'persons/profile';
    this.person = null;
  }

  notifyPersonLoaded() {
    this.personLoaded$.next(true);
  }

  getFullName() {
    return [this.person.firstName, this.person.lastName].join(' ');
  }

  getProfileInfo(forceLoad: boolean = false): Observable<Person> {
    if (!this.person || forceLoad) {
      this.personLoaded$.next(false);
      return this.http.get<Person>(this.getFullEndpointUrl()).pipe(
        tap(result => {
          if (result) {
            delete (result as any).company; // Backend returns company but it's not used. Unused property results in firewall block, so it's deleted here. 
            this.person = result;
            this.personLoaded$.next(true);
            const themeName = this.themeService.getThemeName(this.person);
            this.themeService.loadTheme(themeName);
            this.roles = this.getPersonUserRoleCodes();
          }
        })
      );
    } else {
      return of(this.person);
    }
  }

  resendCode() {
    return this.http
      .get(`${this.apiDomain}/api/${this.endpoint}/resendcode`);
  }
  resendLink() {
    return this.http
      .get(`${this.apiDomain}/api/${this.endpoint}/resendlink`);
  }
  removeUnverifiedPhone() {
    return this.http
      .get(`${this.apiDomain}/api/${this.endpoint}/removeunverifiedphone`);
  }
  removeUnverifiedEmail() {
    return this.http
      .get(`${this.apiDomain}/api/${this.endpoint}/removeunverifiedemail`);
  }
  validateEmail(email: string): Observable<any> {
    return this.http.post(`${this.apiDomain}/api/${this.endpoint}/validateEmail`, {
      email
    });
  }

  // Is user permitted access based on passed roles
  isPersonPermittedAccess(roleCodes: UserRoleCode[], companyId?: string, locationId?: string): boolean {
    // if roleCodes array is empty then it is considered that all rolles should have access
    if (roleCodes.length === 0) {
      return true;
    }
    let isPermitted = false;
    let relevantRoles: PersonRole[] = Object.assign([], this.person.roles);
    // Take into account only roles relatet to specific company
    if (companyId !== undefined) {
      relevantRoles = relevantRoles.filter(role => role.companyId === companyId);
    }
    // Go through all relevant roles and find if there is a role with a code which corresponds to at least one of the code which
    // was passed to this function
    relevantRoles.some(personRole => {
      roleCodes.some(rc => {
        isPermitted = personRole.userRoles.some(ur => ur.code === rc);
        if (isPermitted) {
          // Return true if role with certain code is found and break execution of 'some' function
          return true;
        }
      });
      if (isPermitted) {
        // Return true if role with certain code is found and break execution of 'some' function
        return true;
      }
    });
    return isPermitted;
  }
  // Get all roles of the logged in user
  getPersonRoles(): PersonRole[] {
    return this.person.roles;
  }

  getPersonRegistrationId() {
    return this.person.registrationId;
  }

  getServicePermission(serviceType: BusinessServiceType): ServicePermission {
    return this.person && this.person.servicePermissions
      && this.person.servicePermissions.find(sp => sp.serviceType === serviceType);
  }

  hasPermission(serviceType: BusinessServiceType) {
    const servicePermission = this.getServicePermission(serviceType);
    return servicePermission ? servicePermission.allowed : true;
  }

  hasPartner() {
    return this.themeService.getThemeName(this.person) ? true : false;
  }

  getCreateShipmentAllowed() {
    return !!(this.person.servicePermissions
      .find(sp => sp.serviceType === BusinessServiceType.Shipping) as ShipmentServicePermission)?.createShipments?.allowed;
  }

  getIsShippingAllowed() {
    return !!this.person.servicePermissions
      .find(sp => sp.serviceType === BusinessServiceType.Shipping)?.allowed;
  }

  isUserAppraisalAdmin() {
    return this.isPersonPermittedAccess(
      [
        UserRoleCode.PlatformAdministrator,
        UserRoleCode.SuperAdministrator,
        UserRoleCode.JMAppraisalAdministrator
      ]);
  }

  isUserPosAdmin() {
    return this.isPersonPermittedAccess(
      [
        UserRoleCode.JMPointOfSaleAdministrator
      ]);
  }

  isApproved() {
    return this.person.approvalStatus === ApprovalStatus.Approved;
  }

  hasRole(roleCode: UserRoleCode): boolean {
    return this.roles.findIndex(x => x === roleCode) >= 0;
  }

  getPersonUserRoleCodes(): UserRoleCode[] {
    const roles = this.getPersonRoles().map(x => x.userRoles) || [];
    const userRoles = [...[].concat.apply([], roles)];
    return userRoles.map(x => x.code) || [];
  }

  hasCarePlanRole(): boolean {
    const carePlanRoles = [
      UserRoleCode.JMCarePlanAdministrator,
      UserRoleCode.JMCarePlanCallCenter,
      UserRoleCode.JMCarePlanSales,
      UserRoleCode.JMCarePlanClaimProcessingManager,
      UserRoleCode.JMCarePlanClaimProcessor,
      UserRoleCode.JMCarePlanFinance,
      UserRoleCode.JMCarePlanJeweler,
      UserRoleCode.JMCarePlanRepairCenter
    ];
    return carePlanRoles.some(role => this.roles.includes(role));
  }
}
